import { ArrowsUpDownIcon } from '@heroicons/react/24/outline';
import Phone from './../Image/phone.png';
import Phone2 from './../Image/phone2.png';
import Phone3 from './../Image/phone3.png';
import Phone4 from './../Image/phone4.png';
import Phone5 from './../Image/phone5.png';
import Phone6 from './../Image/phone6.png';

export default function Example() {

    const posts = [
        {
          id: 1,
          title: '1',
          href: '#',
          description:
            'Совместные маркетинговые мероприятия с участием Банков Партнёров, Торговыми сетями, сетями АЗС, Спортивными Федерациями, Благотворительными фондами',
        },
        {
            id: 2,
            title: '2',
            href: '#',
            description:
              'Доступ к самой большой клиентской аудитории',
          },
          {
            id: 3,
            title: '3',
            href: '#',
            description:
              'Прямое влияние на покупательскую активность',
          },
          {
            id: 4,
            title: '4',
            href: '#',
            description:
              'Возможность таргетинга на свою Целевую аудиторию',
          },
          {
            id: 5,
            title: '5',
            href: '#',
            description:
              'Увеличение клиентской базы за счет потока клиентов – держателей банковских карт Банков Партнёров',
          },
          {
            id: 6,
            title: '6',
            href: '#',
            description:
              'Повышение узнаваемости Бренда',
          },
      ]

      const login = 'https://client.service-oem.ru/auth/register/foroemedia/login.php';
      const logup = 'https://client.service-oem.ru/auth/register/foroemedia/logup.php';

  return (
    <div className="bg-white">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-11 sm:py-11 lg:py-11">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Присоединиться к участию в программе лояльности.{' '}
              <a href={logup} className="font-semibold text-indigo-600">
                <span className="absolute inset-0" aria-hidden="true" />
                Зарегистрироваться <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                «Биржа Бонусов»
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
                Новый маркетинговый канал на базе Web App в Telegram, с прямым доступом к клиентской аудитории Банков Партнеров
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href={login}
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Авторизоваться
              </a>
              <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                Ознакомиться <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="exchange w-full mx-auto block sm:flex sm:justify-between">
                    <div className="exchange-left w-1/2 w-full">
                        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                            {posts.map((post) => (
                                <article key={post.id} className="flex max-w-xl w-full text-center h-auto flex-col items-start justify-between bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl shadow-xl">
                                  <div className="group relative">
                                    <h3 className="text-3xl text-center mt-7 font-semibold leading-6 text-white group-hover:text-gray-600">
                                      <a href={post.href}>
                                        <span className="absolute inset-0" />
                                        {post.title}
                                      </a>
                                    </h3>
                                    <p className="mt-5 ml-11 mr-11 pb-14 line-clamp-3 text-sm leading-6 text-slate-50">{post.description}</p>
                                  </div>
                                </article>
                            ))}
                        </div>
                    </div>
                    <div className="exchange-right w-full grid items-center border-t border-gray-200 pt-10 sm:w-1/2 sm:mt-16 sm:pt-16 lg:mx-0">
                        <img className="my-auto rounded-2xl w-full h-auto" src={Phone} alt=""/>
                    </div>
            </div>
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className='my-20 mx-auto w-full border-t border-gray-200'>
                <h2 className="text-3xl mt-20 font-bold tracking-tight text-gray-900 sm:text-4xl">Потребителям</h2>
            </div>
            <div className='block w-full lg:flex lg:justify-between'>
                <div className='text w-full lg:w-1/2'>
                    <p className='w-full h-auto mt-5 pb-14 text-sm leading-6 text-black'>
                    Возможность хранения, отправки, получения, обмена, списания Бонусов полученных от Производителей. Покупайте продукцию партнеров- Производителей, получайте Бонусы, оплачивайте ими товары, а так же топливо на партнерских сетях АЗС
                    </p>
                </div>
                <div className='image hidden lg:block lg:w-1/2'>
                    <ArrowsUpDownIcon className='text-gray-400 rotate-90 lg:w-2/6 lg:float-right ' aria-hidden="true" />
                </div>
            </div>
            <div className='block w-full my-20 mx-auto w-full border-t border-gray-200 lg:flex lg:justify-between'>
                <div className='text w-full lg:w-1/2'>
                    <h2 className='text-3xl mt-20 font-bold tracking-tight text-gray-900 sm:text-4xl'>Производителям товаров</h2>
                    <p className='w-full h-auto mt-7 pb-14 text-sm leading-6 text-black lg-20'>
                    Возможность самостоятельно влиять на повышение спроса на свою продукцию за счет использования новых маркетинговых механик ОЕМ-Service. Начисляйте Бонусы покупателям прямиком в их цифровые кошельки                    
                    </p>
                    <div className='btn-connect text-center'>
                        <button className='w-44 mt-2 px-7 py-3 text-white bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl shadow-xl lg:mt-11'><a href={login}>Присоединиться</a></button>
                    </div>
                </div>
                <div className='image block lg:w-1/2'>
                    <img className="mt-11 lg:mt-0" src={Phone2} alt="" />
                </div>
            </div>
            <div className='block w-full my-20 mx-auto w-full border-t border-gray-200 lg:flex lg:justify-between lg:flex-row-reverse'>
                <div className='text w-full lg:w-1/2'>
                    <h2 className='text-3xl mt-20 font-bold tracking-tight text-gray-900 sm:text-4xl'>Торгово-сервисным предприятиям</h2>
                    <p className='w-full h-auto mt-7 pb-14 text-sm leading-6 text-black lg-20'>
                    Увеличение покупательской активности и суммы среднего чека за счет функционала по автоматической идентификации/авторизации банковских карт, позволяющего определить тип Клиента B2B или B2C и наличие финансовых преференций в виде прямых скидок, кэшбеков или цифровых Бонусов Производителей                    
                    </p>
                    <div className='btn-connect text-center'>
                        <button className='w-44 mt-2 px-7 py-3 text-white bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl shadow-xl lg:mt-11'><a href={login}>Присоединиться</a></button>
                    </div>
                </div>
                <div className='image block lg:w-1/2'>
                    <img className="mt-11 lg:mt-0" src={Phone3} alt="" />
                </div>
            </div>
            <div className='block w-full my-20 mx-auto w-full border-t border-gray-200 lg:flex lg:justify-between'>
                <div className='text w-full lg:w-1/2'>
                    <p className='w-full h-auto mt-7 pb-14 text-sm leading-6 text-black lg-20'>
                    Онлайн сервис для оплаты топлива Цифровыми Бонусами от Производителей на партнерских сетях АЗС (более 10 тыс. заправок) через электронный кошелек на базе Telegram                    
                    </p>
                    <div className='btn-connect text-center'>
                        <button className='w-44 mt-2 px-7 py-3 text-white bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl shadow-xl lg:mt-11'><a href={login}>Присоединиться</a></button>
                    </div>
                </div>
                <div className='image block lg:w-1/2'>
                    <img className="mt-11 lg:mt-0" src={Phone4} alt="" />
                </div>
            </div>
            <div className='block w-full my-20 mx-auto w-full border-t border-gray-200 lg:flex lg:justify-between lg:flex-row-reverse'>
                <div className='text w-full lg:w-1/2'>
                    <h2 className='text-3xl mt-20 font-bold tracking-tight text-gray-900 sm:text-4xl'>Благотворительным фондам</h2>
                    <p className='w-full h-auto mt-7 pb-14 text-sm leading-6 text-black lg-20'>
                    Увеличение эффективности оказания адресной помощи малоимущим группам граждан, многодетным семьям и другим социально-уязвимым категориям населения. Технология OEM-Service позволяет обеспечить целевое доведение социальной помощи напрямую конечному потребителю. Возможность установки запрета на обналичивание/перевод субсидированных средств или их использование на соответствующие категории товаров Производителей в рамках установленного товарного лимита                    
                    </p>
                    <div className='btn-connect text-center'>
                        <button className='w-44 mt-2 px-7 py-3 text-white bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl shadow-xl lg:mt-11'><a href={login}>Присоединиться</a></button>
                    </div>
                </div>
                <div className='image block lg:w-1/2'>
                    <img className="mt-11 lg:mt-0" src={Phone5} alt="" />
                </div>
            </div>
            <div className='block w-full my-20 mx-auto w-full border-t border-gray-200 lg:flex lg:justify-between'>
                <div className='text w-full lg:w-1/2'>
                    <h2 className='text-3xl mt-20 font-bold tracking-tight text-gray-900 sm:text-4xl'>Спортивным Федерациям</h2>
                    <p className='w-full h-auto mt-7 pb-14 text-sm leading-6 text-black lg-20'>
                    Цифровизация Спортивного паспорта с целью доведения полагающихся целевых привилегий спортсменам и ветеранам спорта, занимающимся профессиональным и любительским спортом. Получение положительного общественного мнения, что обеспечит участникам проекта укрепление имиджа организации с высоким уровнем социальной ответственности. Развитие мотивации для ведения здорового образа жизни, занятий физической культурой и спортом                    
                    </p>
                    <div className='btn-connect text-center'>
                        <button className='w-44 mt-2 px-7 py-3 text-white bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl shadow-xl lg:mt-11'><a href={login}>Присоединиться</a></button>
                    </div>
                </div>
                <div className='image block lg:w-1/2'>
                    <img className="mt-11 lg:mt-0" src={Phone6} alt="" />
                </div>
            </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}