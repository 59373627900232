import {BuildingOffice2Icon, BuildingStorefrontIcon, CurrencyDollarIcon, MapPinIcon, PaperAirplaneIcon, TrophyIcon, UserGroupIcon} from '@heroicons/react/24/outline';
  
  export default function Example() {
    return (
      <div className="bg-white pb-24 sm:pb-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Биржа бонусов</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
                Цифровая экосистема на основе технологии блокчейн
            </p>
          </div>
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-72 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-2"> 
              <article className="flex max-w-xl flex-col items-start justify-between">
                <div className="group relative flex justify-between w-full">
                  <div className='text'>
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      Производителям
                    </h3>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">прямой доступ ко всей аудитории «Биржа Бонусов»</p>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">74 млн. пользователей</p>
                   </div>
                  <BuildingOffice2Icon className='h-11 w-11 p-1 mt-7 ml-7 bg-sky-700 text-white rounded-md' aria-hidden="true" />
                </div>
              </article>
              <article className="flex max-w-xl flex-col items-start justify-between">
                <div className="group relative flex justify-between w-full">
                    <CurrencyDollarIcon className='h-11 w-11 p-1 mt-7 mr-7 bg-sky-700 text-white rounded-md' aria-hidden="true" />
                    <div className='text'>
                        <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                            Банковским клиентам
                        </h3>
                        <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">доступ к «Биржа бонусов»</p>
                        <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">74 млн. пользователей</p>
                  </div>
                </div>
              </article>
          </div>
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-32 gap-y-16 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3"> 
          <article className="flex max-w-xl flex-col items-start justify-between">
                <div className="group relative flex justify-between w-full">
                  <div className='text'>
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    Спортивным федерациям
                    </h3>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">«Паспорт спортсмена»</p>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">40 млн. членов организации</p>
                   </div>
                  <TrophyIcon className='h-11 w-11 p-1 mt-7 ml-7 bg-sky-700 text-white rounded-md' aria-hidden="true" />
                </div>
              </article>
              <article className="inline-block max-w-xl text-center flex-col items-start justify-between">
                <div className="group relative inline-block w-full">
                    <h3 className="mt-3 text-lg text-center font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    «Биржа Бонусов»
                    </h3>
                    <p className="mt-5 line-clamp-3 text-center text-sm leading-6 text-gray-600">Block chain TON</p>
                </div>
              </article>
              <article className="flex max-w-xl flex-col items-start justify-between">
                <div className="group relative flex justify-between w-full">
                  <PaperAirplaneIcon className='h-11 w-11 p-1 mt-7 mr-7 bg-sky-700 text-white rounded-md' aria-hidden="true" />
                  <div className='text'>
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    Telegram Wallet TON
                    </h3>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">новый продукт «Биржа Бонусов»</p>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">800 млн. пользователей</p>
                   </div>
                </div>
              </article>
          </div>
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-32 gap-y-16 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3"> 
          <article className="flex max-w-xl flex-col items-start justify-between">
                <div className="group relative flex justify-between w-full">
                  <div className='text'>
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    Благотворительным фондам
                    </h3>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">«Социальный паспорт пользователя»</p>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">20 млн. нуждающихся в помощи</p>
                   </div>
                  <UserGroupIcon className='h-11 w-11 p-1 mt-7 ml-7 bg-sky-700 text-white rounded-md' aria-hidden="true" />
                </div>
              </article>
              <article className="inline-block max-w-xl text-center flex-col items-start justify-between">
                <div className="group relative inline-block w-full">
                    <div className='text'>
                        <h3 className="mt-3 text-lg text-center font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                        Торгово-сервисным предприятиям 
                        </h3>
                        <p className="mt-5 line-clamp-3 text-center text-sm leading-6 text-gray-600">прямой доступ к держателям банковских карт</p>
                        <p className="mt-5 line-clamp-3 text-center text-sm leading-6 text-gray-600">74 млн. пользователей</p>
                    </div>
                    <BuildingStorefrontIcon className='h-11 w-11 p-1 mt-7 mx-auto bg-sky-700 text-white rounded-md' aria-hidden="true" />
                </div>
              </article>
              <article className="flex max-w-xl flex-col items-start justify-between">
                <div className="group relative flex justify-between w-full">
                  <MapPinIcon className='h-11 w-11 p-1 mt-7 mr-7 bg-sky-700 text-white rounded-md' aria-hidden="true" />
                  <div className='text'>
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    АЗС 
                    </h3>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">сервис онлайн заправки в Wallet Telegram</p>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">12.000 точек</p>
                  </div>
                </div>
              </article>
          </div>
        </div>
      </div>
    )
  }