import React, { useState } from 'react';
import { ArrowDownIcon } from '@heroicons/react/24/outline';

const Faq = () => {
  // Создаем массив состояний для отслеживания состояния каждого вопроса
  const [isOpen, setIsOpen] = useState([false, false, false, false]);

  // Функция для переключения состояния открытия/закрытия списка для каждого вопроса
  const toggleList = (index) => {
    const updatedState = isOpen.map((item, i) => (i === index ? !item : item));
    setIsOpen(updatedState);
  };

  return (
    <div className='mx-auto max-w-7xl px-6 lg:px-8'>
      <div className='mx-auto lg:mx-0'>
        <h2 className='text-3xl mt-20 font-bold tracking-tight text-gray-900 sm:text-4xl'>Частые вопросы</h2>
        {/* Проходим по массиву состояний и рендерим вопросы с текстом */}
        {isOpen.map((state, index) => (
          <div className='question bg-white mt-11 rounded-s-xl shadow-2xl' key={index}>
            <div className='question1'>
                <h2 className='bg-white text-2xl ml-5 pt-5 pb-5 flex justify-between' onClick={() => toggleList(index)}>
                    {index === 0 && "Что такое закрывающие документы по совершенным операциям для корпоративных клиентов?"}
                    {index === 1 && "Как подключиться к Партнерской сети?"}
                    {index === 2 && "Каким способом осуществляется направление Бухгалтерских документов в адрес клиентов?"}
                    {index === 3 && "Как клиент, использующий Банковскую карту, узнает о возможности обслуживания на Партнерской сети?"}
                  {/* В зависимости от состояния отображаем стрелку вверх или вниз */}
                  {state ? (
                    <ArrowDownIcon className='text-black w-9 h-min pt-5 mr-3 transform rotate-180' aria-hidden="true"/>
                  ) : (
                    <ArrowDownIcon className='w-9 h-min pt-3 pr-5' aria-hidden="true" />
                  )}

                </h2>
            </div> 
            {/* Условный рендеринг текста в зависимости от состояния isOpen для текущего вопроса */}
            {state && (
                <p className='text-xl text-left mt-9 ml-5 pb-11 text-slate-500'>
                    {index === 0 && (
                      <>
                        Это сервис, позволяющий автоматически формировать закрывающие документы по операциям, совершенным по бизнес-карте на Партнерской сети ОЕМ:<br />
                        Участвуйте в проекте и получайте преимущества:<br />
                        - Доступ к широчайшей сети корпоративных клиентов крупнейших банков страны<br />
                        - Минимизация затрат на маркетинговые мероприятия;<br />
                        - Предоставление современного сервиса клиентам;<br />
                        - Исполнение требований Федерального закона от 22.05.2003 №54-ФЗ.О применении контрольно-кассовой техники при осуществлении наличных денежных расчетов и (или) расчетов с использованием платежных карт.
                      </>
                    )}
                    {index === 1 && (
                      <>
                        Заполнить заявку на сайте.<br />
                        Связаться с нами напрямую по номеру 8 (800) 550-27-40.
                      </>
                    )}
                    {index === 2 && (
                      <>Первичные бухгалтерские документы будут автоматически сформированы и направлены в Банк-клиент корпоративного клиента.</>
                    )}
                    {index === 3 && (
                      <>Информирование корпоративных клиентов о возможности обслуживания на Вашей сети будет осуществляться посредством смс-рассылок и push-уведомления.</>
                    )}
                </p>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Faq;