import Magnit from './../Image/magnit.png';
import Magnolia from './../Image/magnolia1.png';
import Fruto from './../Image/frutonana.png';
import Obi from './../Image/obi.png';
import Danone from './../Image/danone.png';
import territory from './../Image/map.png';

const Map = 'https://oemedia.ru/map/sbrf/index.html';

const countrys = [ 'KAZAKHSTAN', 'UZBEKISTAN', 'KIRGHIZSTAN', 'GEORGIA', 'UAE', 'JAPAN' ]

export default function Example() {
    return (
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto lg:mx-0">
            <div className="w-full h-72">
                <iframe className="w-full h-72 rounded-2xl shadow-xl lg:h-96" src={Map} />
            </div>
            <div className="bg-white py-11 sm:py-20">
      <div className="mx-auto max-w-7xl mt-11 px-6 lg:px-8 lg:mt-20">
        <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
          Наши партнеры
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={Magnit}
            alt="Transistor"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={Magnolia}
            alt="Reform"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={Fruto}
            alt="Tuple"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src={Obi}
            alt="SavvyCal"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            src={Danone}
            alt="Statamic"
            width={158}
            height={48}
          />
        </div>
      </div>
    </div>
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className='my-20 mx-auto w-full border-t border-gray-200'>
                <h2 className="text-3xl mt-20 font-bold tracking-tight text-gray-900 sm:text-4xl">Международное развитие</h2>
            </div>
            <div className='grid grid-cols-2 gap-8 sm:mt-20 sm:grid-cols-3 lg:grid-cols-6'>
                {countrys.map((country) => (
                    <div key={country.name} className="flex flex-col-reverse">
                        <h2 className='text-orange-500'>{country}</h2>
                    </div>
                ))}
            </div>
            <div className='block w-full lg:flex lg:justify-between'>
                <img className='w-full lg:w-1/2' src={territory} alt='' />
                <div className='block w-full lg:w-1/2'>
                    <p className='mt-7 lg:mt-20'>
                    В настоящее время OEM-сервис расширяет географию присутствия на международных рынках Казахстана, Киргизстана, Грузии, Узбекистана, Японии, ОАЭ, что позволяет продемонстрировать высокую заинтересованность в использовании технологического сервиса и его внедрения
                    </p>
                    <h2 className='mt-14 text-2xl text-orange-500 lg:mt-24'>OEM Service</h2>
                </div>
            </div> 
        </div>
    </div>
</div>
);
}